import Vue from 'vue'
import VueRouter from 'vue-router'
import { NotificationProgrammatic as Notification } from 'buefy'
import store from '@/store'
import { getUser } from '@/api/users'
import { hasPermission } from '../utils/permissions'

const TheContainer = () => import('@/containers/TheContainer')

const Home = () => import('@/views/Home')
const Items = () => import('@/views/items/List')
const Boosts = () => import('@/views/boosts/List')
const Leaderboard = () => import('@/views/Leaderboard')
const Users = () => import('@/views/users/List')
const Guild = () => import('@/views/guild/Guild')
const Roles = () => import('@/views/Roles')
const Premium = () => import('@/views/Premium')
const ThankYou = () => import('@/views/ThankYou')
const Support = () => import('@/views/Support')
const Mafff = () => import('@/views/mafff/Home')
const Cookies = () => import('@/views/policies/Cookies')
const Privacy = () => import('@/views/policies/Privacy')
const Terms = () => import('@/views/policies/Terms')

// Id
const IdAuth = () => import('@/views/id/Auth')
const IdCallback = () => import('@/views/id/Callback')

// Error
const Err = () => import('@/views/Error')

Vue.use(VueRouter)

const routes = [
  {
    path: '/id',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: 'auth',
        name: 'IdAuth',
        component: IdAuth
      },
      {
        path: 'callback',
        name: 'IdCallback',
        component: IdCallback
      }
    ]
  },
  {
    path: '/',
    component: TheContainer,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: 'items',
        name: 'Items',
        component: Items
      },
      {
        path: 'boosts',
        name: 'Boosts',
        component: Boosts
      },
      {
        path: 'leaderboard',
        name: 'Leaderboard',
        component: Leaderboard
      },
      {
        path: 'vip',
        name: 'VIP',
        component: Premium
      },
      {
        path: 'thankyou',
        name: 'Thank You',
        component: ThankYou
      },
      {
        path: 'terms',
        name: 'Terms',
        component: Terms
      },
      {
        path: 'cookies',
        name: 'Cookies',
        component: Cookies
      },
      {
        path: 'privacy',
        name: 'Privacy',
        component: Privacy
      },
      {
        path: 'support',
        name: 'Support',
        component: Support
      },
      {
        path: 'users',
        name: 'Users',
        component: Users,
        meta: {
          accessPermission: 'users.list'
        }
      },
      {
        path: 'guild',
        name: 'Guild',
        component: Guild,
        meta: {
          accessPermission: 'guild.view'
        }
      },
      {
        path: 'roles',
        name: 'Roles',
        component: Roles,
        meta: {
          accessPermission: 'guild.update.roles'
        }
      },
      {
        path: 'mafff',
        name: 'Mafff',
        component: Mafff
      },
      {
        path: '/err/:id',
        name: 'Error',
        component: Err
      },
      {
        path: '*',
        component: {
          render (c) { return c('router-view') }
        },
        redirect: { name: 'Error', params: { id: '404' }, query: {} }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const whitelisted = ['Home', 'IdAuth', 'IdCallback', 'Error', 'Support', 'Cookies', 'Privacy', 'Terms', 'Thank You', 'Leaderboard']
router.beforeEach(async (to, from, next) => {
  if (whitelisted.includes(to.name ?? 'Home')) {
    next()
    return
  }

  let { user } = store.state.user
  if (!user?.id) {
    try {
      user = await getUser()
      await store.dispatch('user/setUser', user)
    } catch (err) {
      next({ name: 'IdAuth' })
      return
    }
  }

  // Route permissions
  if (to.meta.accessPermission && !hasPermission(to.meta.accessPermission)) {
    Notification.open({
      duration: 3000,
      message: `You need the <b>${to.meta.accessPermission}</b> permission to access the <b>${to.name}</b> route.`,
      type: 'is-danger'
    })
    next({ name: 'Home' })
    return
  }

  next()
})

export default router
