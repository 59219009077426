import { getCookie, setCookie, removeCookie } from '@/utils/cookies'

const state = {
  token: getCookie('token'),
  user: {
    id: '',
    username: '',
    discriminator: '',
    avatar: '',
    settings: {
      msgCount: 0,
      vcTime: 0,
      level: 0,
      totalXp: 0,
      currentXp: 0,
      tickets: 0,
      clappyImageUrl: '',
      permissions: [],
      items: []
    }
  }
}

const mutations = {
  SET_TOKEN (state, token) {
    state.token = token
    setCookie('token', token, 7)
  },
  REMOVE_TOKEN (state) {
    state.token = null
    removeCookie('token')
  },
  SET_USER (state, user) {
    for (const prop in user) { state.user[prop] = user[prop] }
  }
}

const actions = {
  setUser ({ commit }, user) {
    commit('SET_USER', user)
  },
  login ({ commit }, data) {
    commit('SET_USER', data.user)
    commit('SET_TOKEN', data.token)
  },
  logout ({ commit }) {
    commit('SET_USER', {})
    commit('REMOVE_TOKEN', '')
  }
}

export default {
  namespaced: true, state, actions, mutations
}
