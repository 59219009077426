// import store from '@/store'
import request from '@/utils/request'

export const getUsers = async () => {
  return request({
    url: '/users',
    method: 'get'
  })
}

export const getUser = async (code) => {
  return request({
    url: '/users/@me',
    method: 'get'
  })
}

export const createUser = async (data) => {
  return request({
    url: '/users',
    method: 'post',
    data
  })
}

export const updateUser = async (id, data) => {
  return request({
    url: `/users/${id}`,
    method: 'put',
    data
  })
}

export const purchaseItem = async (id, data) => {
  return request({
    url: `/users/${id}/items`,
    method: 'put',
    data
  })
}

export const deactivateItem = async (id, data) => {
  return request({
    url: `/users/${id}/items`,
    method: 'delete',
    data
  })
}

export const purchaseBoost = async (id, data) => {
  return request({
    url: `/users/${id}/boosts`,
    method: 'put',
    data
  })
}

export const deleteUser = async (id) => {
  return request({
    url: `/users/${id}`,
    method: 'delete'
  })
}
