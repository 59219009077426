import axios from 'axios'
import store from '@/store'
import router from '@/router'

const request = axios.create({
  baseURL: process.env.VUE_APP_API_URL, // url = base url + request url
  timeout: 30000 // request timeout
})

request.interceptors.request.use(
  config => {
    if (store.state.user.token) config.headers.authorization = `Bearer ${store.state.user.token}`
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  response => {
    if (response.status === 512) throw new Error() // Cloudflare always-on response
    return response.data
  },
  error => {
    if (!error.response) {
      const json = error.toJSON()
      const url = new URL(json.config.baseURL)

      if (url.pathname !== '' && url.pathname.startsWith('/err')) {
        router.push({
          name: 'Error',
          params: { id: '503' },
          query: {}
        })
      }

      return Promise.reject(error)
    }

    // displayAlert(error.response.data.message ?? 'Erro inesperado.')

    switch (error.response.status) {
      case 401: // Unauthorized
      case 403: // Forbidden
        console.log('Unauthorized/Forbidden')
        // router.push({ name: 'IdLogin', query: { redirect: window.location.pathname ?? '/' } })
    }

    // router.push({ name: 'Error', params: { id: error.response.status }, query: {} })

    return Promise.reject(error.response.data)
  }
)

export default request
