import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import Buefy from 'buefy'
import './sass/darkmode.scss'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.use(Buefy, {
  defaultDialogConfirmText: 'Submit',
  defaultSnackbarPosition: 'is-top-right',
  defaultNotificationPosition: 'is-top-right'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
