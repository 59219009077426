import store from '@/store'

export const hasPermission = (needed) => {
  if (!store.state.user.user.id || store.state.user.user.settings.blacklisted) return false
  const permissions = store.state.user.user.settings.permissions

  if (permissions.includes('*') || permissions.includes(needed)) return true
  const [mod, part] = needed.split('.')
  if (permissions.includes(`${mod}.*`) || permissions.includes(`${mod}.${part}.*`)) return true

  return false
}
